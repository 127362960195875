import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import SideBar from "@components/article/sidebar"
import PropTypes from "prop-types"
import { ArticleContainer } from "@components/common/article"
import Heading from "@components/elements/heading"
import { formatSeo } from "@utils/format-seo"
import { HTMLWrapper } from "@components/common/html-wrapper"
import Image from "@components/image"
import { StyledHTML } from "@components/layout/styled-html"

const Article = ({ data: { article } }) => {
  const image = article.image
  const seo = formatSeo(article.seo, null, null, null, image, "article")

  return (
    <Layout
      image={image.cdn}
      body={article.body}
      date_updated={article.date_updated}
      seo={seo}
    >
      <article>
        <Heading
          maxWidth="35ch"
          padding="2rem 0 4rem"
          paddingMd="2rem 0 2rem"
          paddingSm="2rem 0 2rem"
          margin="0 auto"
          fontSize="3rem"
          center
        >
          {article.header}
        </Heading>
        <ArticleContainer large={!article.hide_sidebar}>
          {!article.hide_sidebar && (
            <SideBar
              title={article.category.cta_title}
              description={article.category.cta_text}
            />
          )}
          <StyledHTML>
            <Image
              style={{ marginBottom: "2rem" }}
              file={article.image}
              radius={0.5}
            />
            <HTMLWrapper cta html={article.body} images={article.body_images} />
          </StyledHTML>
        </ArticleContainer>
      </article>
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Article

export const pageQuery = graphql`
  query ArticlePageQuery($id: String!) {
    article: articles(id: { eq: $id }) {
      status
      id
      header
      category {
        cta_title
        cta_text
      }
      body
      body_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      hide_sidebar
      date
      date_updated
      image {
        id
        title
        cdn
        placeholder
      }
      seo {
        ...seoData
      }
    }
  }
`
