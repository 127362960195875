import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import theme from "@styles/theme"
import media from "@styles/media"
import { Sticky } from "@components/common/article"
import Span from "@components/elements/span"
import PropTypes from "prop-types"
import SquareColourfulCTA from "../cta/colourful/square"

const StyledSideBar = styled.nav`
  ${props =>
    !props.simplified &&
    css`
    margin: 1rem 0;
    box-shadow ${theme.shadow.subtle};
    background: white;
  `}
  border-radius: 1rem;
  overflow: hidden;
  flex-basis: 300px;
  flex-grow: 1;
  min-width: 300px;
  height: min-content;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    ul {
      border-left: 1px #000 solid;
      padding: 0.125rem 0;
      margin: 0.75rem 0 0.75rem 1rem;
    }
  }
  ul > li {
    position: relative;
    display: block;
    margin: 0;
  }
  ul li a {
    padding: ${props => (props.simplified ? "0 1rem" : "0.5rem 1rem")};
  }
  ${media.md`
    display: none;
  `}
`

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props =>
    props.active ? theme.color.blue400 : theme.color.blue600};
  font-weight: ${props => (props.active ? 700 : 500)};
  display: block;
  position: relative;
  padding: 0.5rem 0rem;
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${props => !props.simplified && "#eee"};
    color: ${props => props.simplified && theme.color.indigo500};
  }
`

const useHeadingsData = (manualList) => {
  const [nestedHeadings, setNestedHeadings] = useState([])

  useEffect(() => {
    if(manualList) {
      setNestedHeadings(manualList)
    } else {
      const headingElements = Array.from(document.querySelectorAll("h2"))
      const newNestedHeadings = getNestedHeadings(headingElements)
      setNestedHeadings(newNestedHeadings)
    }
  }, [manualList])

  return { nestedHeadings }
}

const getNestedHeadings = headingElements => {
  const nestedHeadings = []
  var index = 0

  headingElements.forEach(heading => {
    const { innerText: title, id } = heading
    // Change the id of the heading to be the index of the heading
    var navid = id !== "" ? id : "sidenav-" + index
    heading.id = navid
    nestedHeadings.push({ id: navid, title })
    index++
  })

  return nestedHeadings
}

const useIntersectionObserver = (setActiveId, nestedHeadings) => {
  const headingElementsRef = useRef({})
  useEffect(() => {
    const callback = headings => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        if (headingElement.target.innerText)
          map[headingElement.target.id] = headingElement
        return map
      }, headingElementsRef.current)

      const visibleHeadings = []
      Object.keys(headingElementsRef.current).forEach(key => {
        const headingElement = headingElementsRef.current[key]
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement)
      })

      const getIndexFromId = id =>
        headingElements.findIndex(heading => heading.id === id)

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id)
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        )
        setActiveId(sortedVisibleHeadings[0].target.id)
      } else if (visibleHeadings.length === 0) {
        // We throw this out to keep the position of the active header.
      }
    }

    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px 0px -60% 0px",
    })

    const headingElements = nestedHeadings.reduce((acc, heading) => {
      acc.push(heading)
      if(heading.children) {
        acc.push(...Object.values(heading.children))
      }
      return acc
    }, []).map(({id}) => document.getElementById(id))

    headingElements.forEach(element => observer.observe(element))

    return () => observer.disconnect()
  }, [setActiveId, nestedHeadings])
}

const SideBar = ({
  cta = true,
  message = `In this article, you'll learn:`,
  title,
  description,
  simplified,
  manualList
}) => {
  const [activeId, setActiveId] = useState()
  const { nestedHeadings } = useHeadingsData(manualList)
  useIntersectionObserver(setActiveId, nestedHeadings)

  return (
    <div>
      {cta && <SquareColourfulCTA title={title} description={description} />}
      <Sticky>
        <StyledSideBar simplified={simplified}>
          <Span
            display="block"
            padding="0 1rem"
            margin={simplified ? "0 0 1rem" : "1rem 0"}
            fontSize={simplified ? "1.5rem" : "1rem"}
            fontWeight="700"
          >
            {message}
          </Span>
          <ul>
            {nestedHeadings.map(link => {
              return (
                <li key={link.id}>
                  <StyledLink
                    simplified={simplified}
                    href={`#${link.id}`}
                    active={activeId === link.id}
                    onClick={e => {
                      e.preventDefault()
                      document
                        .querySelector(`#${link.id}`)
                        .scrollIntoView({ behavior: "smooth" })
                    }}
                  >
                    {link.title}
                  </StyledLink>
                  {link.children && <ul>
                    {Object.values(link.children).map((childLink) => (
                      <li key={childLink.id}>
                        <StyledLink
                          simplified={simplified}
                          href={`#${childLink.id}`}
                          active={activeId === childLink.id}
                          onClick={e => {
                            e.preventDefault()
                            document
                              .querySelector(`#${childLink.id}`)
                              .scrollIntoView({ behavior: "smooth" })
                          }}
                        >
                          {childLink.title}
                        </StyledLink>
                      </li>
                    ))}  
                  </ul>}
                </li>
              )
            })}
          </ul>
        </StyledSideBar>
      </Sticky>
    </div>
  )
}

SideBar.propTypes = {
  cta: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  simplified: PropTypes.bool,
  manualList: PropTypes.array
}

export default SideBar
