import React from "react"
import Span from "@components/elements/span"
import Button from "@components/elements/button"
import Div from "@components/elements/div"
import { CtaContainer } from "@components/cta/colourful/styled"
import PropTypes from "prop-types"
import { useFullCta } from "@hooks/use-cta"
import { KlipsLogo } from "@components/common/logo"

const SquareColourfulCTA = ({ title, description }) => {
  const { cta } = useFullCta(title, description)

  return (
    <CtaContainer
      padding="2rem 1.25rem 2.5rem"
      minWidth="300px"
    >
      <Div zIndex={10} position="relative">
        <KlipsLogo style={{ margin: "0 auto 1rem" }} />
        <Span
          padding="0 0 2rem"
          lineHeight="1.3"
          fontSize="1.4rem"
          fontWeight="700"
          display="block"
          color="white"
          center
        >
          {cta.description}
        </Span>
        <Button.Klips
          data-cta-location="cta"
          arrow={false}
          noticeColor="white"
        />
      </Div>
    </CtaContainer>
  )
}

SquareColourfulCTA.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SquareColourfulCTA
